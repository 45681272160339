<template>
  <div class="tickets-list fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="organizationTicketsList"
                             :data="data"
                             :columns="columnsLabel"
                             :options="options"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @load:more="getTickets()"/>

    <!--  insert ticket prompt  -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="insertTicketPromptStatus"
      @close="insertTicketPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertTicketBTN')">
              <custom-icon color="success"
                           icon="SAVE"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('tickets.organization.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertTicketPromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <insert-organization-ticket @insert="handleReloadTicketsList" />
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button v-show="false"
            id="insertTicket"
            @click="insertTicketPromptStatus = true"/>
  </div>
</template>

<script>
import CustomIcon from '../../../../../components/customIcon/customIcon'
import InsertOrganizationTicket from "../insert/insertOrganizationTicket";
import {getOrganizationTickets} from "../../../../../http/requests/tickets";

export default {
  name: 'ticketsOrganizationList',
  components: {InsertOrganizationTicket, CustomIcon},
  metaInfo () {
    return {
      title: this.$t('tickets.organization.list.title')
    }
  },
  data () {
    return {
      insertTicketPromptStatus: false,
      requestSent: false,
      priceRequestSent: false,
      loadingTimer: 0,
      options: {
        // id: 'organizationTicketsList',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'status',
          i18n: 'tickets.organization.table.header.status',
          width: 'calc(100% / 4)',
          minWidth: 170,
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('tickets.organization.labels.all'),
              value: -1
            },
            {
              label: this.$t('tickets.organization.statusTypes.created'),
              value: 0
            },
            {
              label: this.$t('tickets.organization.statusTypes.creatorAnswer'),
              value: 1
            },
            {
              label: this.$t('tickets.organization.statusTypes.receiverAnswer'),
              value: 2
            },
            {
              label: this.$t('tickets.organization.statusTypes.pending'),
              value: 3
            },
            {
              label: this.$t('tickets.organization.statusTypes.completed'),
              value: 4
            },
          ],
          align: 'center',
          sortable: true
        },
        {
          field: 'lastUpdate',
          i18n: 'tickets.organization.table.header.lastUpdate',
          width: 'calc(100% / 4)',
          minWidth: 150,
          filter: true,
          filterType: 'date',
          align: 'center',
          sortable: true
        },
        {
          field: 'subject',
          i18n: 'tickets.organization.table.header.subject',
          width: 'calc(100% / 4)',
          minWidth: 170,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ],
          align: 'center',
          sortable: true
        },
        {
          field: 'user',
          i18n: 'tickets.organization.table.header.user',
          width: 'calc(100% / 4)',
          minWidth: 170,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ],
          align: 'center',
          sortable: true
        },
        {
          field: 'creator',
          i18n: 'tickets.organization.table.header.creator',
          width: 'calc(100% / 4)',
          minWidth: 170,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ],
          align: 'center',
          sortable: true
        },
        {
          field: 'rowNumber',
          i18n: 'tickets.organization.table.header.row',
          width: '60px',
          minWidth: 60,
          align: 'center',
          // sortable: true,
          locked: true,
          footer: {}
        }
      ],
      statusTypes: {
        0: this.$t('tickets.organization.statusTypes.created'),
        1: this.$t('tickets.organization.statusTypes.creatorAnswer'),
        2: this.$t('tickets.organization.statusTypes.receiverAnswer'),
        3: this.$t('tickets.organization.statusTypes.pending'),
        4: this.$t('tickets.organization.statusTypes.completed'),
      },
      data: [],
      selectedPaymentGateway: {},
      filters: [],
      sorts: ['order[0]=answered_at,desc'],
      page: 1,
      endedList: false,
      actions: [
        {
          toolbar: [
            {
              id: 'insertTicket',
              icon: 'icon-plus',
              iconPack: 'feather',
              color: 'success',
              permission: 'organizational_ticket.create'
            }
          ],
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'icon-printer',
              iconPack: 'feather'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'icon-download',
              iconPack: 'feather'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    }, 50)

    this.getTickets()
  },
  methods: {
    getTickets () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.organizationTicketsList && this.data.length === 0) this.$refs.organizationTicketsList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.organizationTicketsList && this.data.length > 0) this.$refs.organizationTicketsList.loadMoreStatus = 'Loading'

            getOrganizationTickets(this.page, this.filters, this.sorts).then(response => {
              this.endedList = response.data.data.length === 0
              const tickets = response.data.data

              tickets.forEach((ticket) => {
                this.data.push({
                  route: {
                    name: 'organizationTicketDetail',
                    params: {id: ticket.id}
                  },
                  id: ticket.id,
                  rowNumber: this.data.length + 1,
                  subject: ticket.subject || '',
                  creator: ticket.creator ? `${ticket.creator.name || ''} ${ticket.creator.family || ''}` : this.$t('tickets.organization.labels.unknown'),
                  user: ticket.user ? `${ticket.user.name || ''} ${ticket.user.family || ''}` : this.$t('tickets.organization.labels.unknown'),
                  lastUpdate: {
                    value: ticket.answered_at || '',
                    styles: 'direction: ltr;'
                  },
                  seen: ticket.seen ? this.$t('tickets.organization.seenStatusTypes.seen') : this.$t('tickets.organization.seenStatusTypes.notSeen'),
                  status: this.statusTypes[ticket.status],
                  sendStatus: ticket.sent ? this.$t('tickets.organization.sendStatusTypes.send') : this.$t('tickets.organization.sendStatusTypes.notSend'),
                  styles: ticket.seen ? 'backgroundColor: #f0f0f0;' : ''
                })
              })

              this.page = response.data.pagination.current_page + 1
              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer.value = response.data.pagination.total
              }

              if (this.$refs.organizationTicketsList) this.$refs.organizationTicketsList.loadMoreStatus = ''

              this.requestSent = false
            }).catch(() => {

              if (this.$refs.organizationTicketsList) this.$refs.organizationTicketsList.loadMoreStatus = 'Danger'

              this.requestSent = false
            })
          } else {
            this.requestSent = false
          }
        }, 400)
      }
    },
    setFilter (filters) {
      if (!this.getFiltersStatus) {
        let filters_list = []
        const attributes = []
        Object.keys(filters).forEach((key) => {
          switch (key) {

          case 'creator':
            if (filters[key].search !== '') filters_list.push(`creator=${filters[key].search}`)
            break

          case 'user':
            if (filters[key].search !== '') filters_list.push(`user=${filters[key].search}`)
            break

          case 'subject':
            if (filters[key].search !== '') filters_list.push(`subject=${filters[key].search}`)
            break

          case 'service':
            if (filters[key].search !== '') filters_list.push(`service=${filters[key].search}`)
            break

          case 'operatorName':
            if (filters[key].search !== '') filters_list.push(`operator=${filters[key].search}`)
            break

          case 'lastUpdate':
            if (filters[key].search !== '') filters_list.push(`answered_at=${filters[key].search}`)
            break

          case 'sendStatus':
            if (filters[key].search.value > -1) filters_list.push(`sent=${filters[key].search.value}`)
            break

          case 'status':
            if (filters[key].search.value > -1) filters_list.push(`status=${filters[key].search.value}`)
            break
          }
        })
        filters_list = [filters_list, ...attributes]

        this.data = []
        this.page = 1
        this.endedList = false
        this.filters = filters_list
        this.getTickets()
      }
    },
    setSort (sorts) {
      const sorts_list = []
      Object.keys(sorts).forEach((key) => {
        switch (key) {

        case 'rowNumber':
          sorts_list.push(`order[0]=id,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'creator':
          sorts_list.push(`order[0]=creator,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'user':
          sorts_list.push(`order[0]=user,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'subject':
          sorts_list.push(`order[0]=subject,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'service':
          sorts_list.push(`order[0]=service,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'operatorName':
          sorts_list.push(`order[0]=operator,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'lastUpdate':
          sorts_list.push(`order[0]=answered_at,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'sendStatus':
          sorts_list.push(`order[0]=sent,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'status':
          sorts_list.push(`order[0]=status,${  sorts[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      if (sorts_list.length === 0) {
        this.sorts.push('order[0]=updated_at,desc')
      }

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getTickets()
    },
    handleReloadTicketsList () {
      this.data = []
      this.page = 1
      this.endedList = false
      this.insertTicketPromptStatus = false

      this.getTickets()
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  }
}
</script>

<style scoped>

</style>
